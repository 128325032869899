import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Warning from "@material-ui/icons/Warning"
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import classNames from 'classnames'
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Table from "@material-ui/core/Table";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {nl2br} from "../tools/strings";
import NavigateBefore from "@material-ui/icons/NavigateBefore";

class Notes extends React.Component{

    constructor(props) {
        super(props)

        this.state = {
            transpondeur: this.props.currentParticipant.transpondeur,
            plateNumber: this.props.currentParticipant.plateNumber,
            updateTranspondeur: false,
            updatePlateNumber: false,
            commentWarning: this.props.currentParticipant.resasCommentaire !== null && this.props.currentParticipant.resasCommentaire.length > 0 && this.props.currentParticipant.resasCommentaire[0] === '!',
            instructorComment: this.props.currentParticipant.instructorComment,
            amount: 0,
            removePayment: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.currentParticipant.transpondeur !== prevProps.currentParticipant.transpondeur
            || this.props.currentParticipant.plateNumber !== prevProps.currentParticipant.plateNumber
            || this.props.currentParticipant.resasCommentaire !== prevProps.currentParticipant.resasCommentaire
            || this.props.currentParticipant.instructorComment !== prevProps.currentParticipant.instructorComment
        ) {
            this.setState({
                transpondeur: this.props.currentParticipant.transpondeur,
                plateNumber: this.props.currentParticipant.plateNumber,
                commentWarning: this.props.currentParticipant.resasCommentaire !== null && this.props.currentParticipant.resasCommentaire.length > 0 && this.props.currentParticipant.resasCommentaire[0] === '!',
                instructorComment: this.props.currentParticipant.instructorComment
            })
        }

    }

    handleSubmit() {
        const instructorComment = this.state.instructorComment !== null && this.state.instructorComment.length > 0 ? this.state.instructorComment : this.props.currentParticipant.instructorComment
        this.props.handleSubmitResa(Object.assign({}, this.props.currentParticipant, {validePresence: true, transpondeur: parseInt(this.state.transpondeur, 10), plateNumber: parseInt(this.state.plateNumber, 10), instructorComment}));
        if (this.state.updateTranspondeur) {
            this.toggleUpdateTranspondeur()
        }
        if (this.state.updatePlateNumber) {
            this.toggleUpdatePlateNumber()
        }
    }

    toggleUpdateTranspondeur() {
        this.setState({
            updateTranspondeur: !this.state.updateTranspondeur
        })
    }

    toggleUpdatePlateNumber() {
        this.setState({
            updatePlateNumber: !this.state.updatePlateNumber
        })
    }

    handleChange(key, e) {
        this.setState({
            [key] : e.target.value
        })
    }

    formattedComment() {
        if (this.state.commentWarning) {
             return nl2br(this.props.currentParticipant.resasCommentaire.substring(1))
        }
        return nl2br(this.props.currentParticipant.resasCommentaire)
    }

    resetParticipant() {
        this.props.resetParticipant().then(() =>  {
            this.props.history.push('/search')
        })
    }

    handleSubmitPayment(paymentChoice) {
        if (this.state.amount > 0) {
            this.props.addPayment({
                amount: parseFloat(this.state.amount),
                paymentChoice
            }).then(() => {
                this.setState({
                    amount: 0
                })
            })
        }
    }

    handleOpenDialogRemovePayment(removePayment) {
        this.setState({
            removePayment
        })
    }

    handleCloseDialogRemovePayment() {
        this.setState({
            removePayment: ''
        })
    }

    handleRemovePayment() {
        this.props.removePayment(this.state.removePayment)
        this.handleCloseDialogRemovePayment()
    }

    render() {

        const optionsExtraCharges = this.props.currentParticipant.resasOpt.filter((option) => option.onTheSpot === true)

        return(
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    <Paper className="formPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Valider la réservation
                        </Typography>
                        {this.props.currentParticipant.resasAlerte === 'Y' &&
                        <Typography component="h5" variant="h6" gutterBottom color={"error"}>
                            <Warning fontSize={"large"} style={{verticalAlign: 'middle'}}/> En attente de documents
                        </Typography>
                        }

                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item  xs={6}>
                                {this.props.currentParticipant.resasCommentaire &&
                                    <Typography component="h5" variant="h6">
                                        Commentaire Camille
                                    </Typography>
                                }
                            </Grid>
                            <Grid item  xs={6}>
                                {this.props.currentParticipant.resasMontant > 0 &&
                                    <Typography component="h5" variant="h6" align={"right"}>
                                        Montant : { this.props.currentParticipant.resasMontant } €
                                    </Typography>
                                }
                            </Grid>

                        </Grid>
                        {this.props.currentParticipant.resasCommentaire &&
                            <div className={"mt2"}>
                                <Typography component="div" gutterBottom>
                                    <div className={classNames({'message-warning': this.state.commentWarning === true, 'message-info': !this.state.commentWarning})} dangerouslySetInnerHTML={{__html: this.formattedComment() }} />
                                </Typography>
                            </div>
                        }

                        {optionsExtraCharges.length > 0 &&
                            <Typography component="h5" variant="h6" align={"right"}>
                                Total options sur place : { optionsExtraCharges.reduce((accumulator, option) => accumulator + option.amount, 0) } €
                            </Typography>
                        }

                        {this.props.currentParticipant.resasPayments.length > 0 &&
                            <React.Fragment>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Montant</TableCell>
                                            <TableCell align="center">Mode de règlement</TableCell>
                                            <TableCell align="center">Supprimer</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.currentParticipant.resasPayments.map((row, idx) => (
                                            <TableRow key={row['@id']}>
                                                <TableCell component="th" scope="row">
                                                    {row.amount}€
                                                </TableCell>
                                                <TableCell align="center">{row.paymentChoice}</TableCell>
                                                <TableCell align="center">
                                                    <Button color="inherit">
                                                        <RemoveCircle onClick={this.handleOpenDialogRemovePayment.bind(this, row['@id'])}/>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Dialog
                                    open={this.state.removePayment.length > 0}
                                    onClose={this.handleCloseDialogRemovePayment.bind(this)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Confirmer la suppression de ce paiement ?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseDialogRemovePayment.bind(this)} color="primary">
                                            Annuler
                                        </Button>
                                        <Button onClick={this.handleRemovePayment.bind(this)} color="secondary" autoFocus>
                                            Confirmer suppression
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        }

                        <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Box>
                                <Typography component="h5" variant="h6" align={"left"}>
                                    Paiement sur place :
                                </Typography>
                            </Box>
                            <Box>
                                <TextField
                                    id="option-amount"
                                    label="Montant"
                                    value={this.state.amount}
                                    required
                                    onChange={this.handleChange.bind(this, 'amount')}
                                    margin="normal"
                                    type="number"
                                    error={this.state.amount <= 0 }
                                    inputProps={{'min': 0}}
                                />
                            </Box>
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.handleSubmitPayment.bind(this, 'CHQ')}
                                >
                                    Chèque
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.handleSubmitPayment.bind(this, 'CB')}
                                >
                                    CB
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.handleSubmitPayment.bind(this, 'ESP')}
                                >
                                    Espèces
                                </Button>
                            </Box>
                        </Box>


                        { this.props.currentParticipant.validePresence &&
                        <div className={'mt2 message-success'}>
                            Réservation validée !
                        </div>
                        }


                        {/*{ !this.props.currentParticipant.validePresence &&*/}
                        <div className="mt2">
                            <TextField
                                id="outlined-multiline-static"
                                label="Commentaire Instructeur"
                                multiline
                                minRows="4"
                                defaultValue={this.state.instructorComment}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange.bind(this, 'instructorComment')}
                            />
                            <div className={"mt2"}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.handleSubmit.bind(this)}
                                >
                                    { !this.props.currentParticipant.validePresence ? 'Valider la réservation' : 'Modifier le commentaire' }
                                </Button>
                            </div>
                        </div>
                        {/* } */}

                        {/*{this.props.currentParticipant.instructorComment &&*/}
                        {/*<div className={"mt2"}>*/}
                        {/*    <Typography component="h5" variant="h6" gutterBottom>*/}
                        {/*        Commentaire instructeur*/}
                        {/*    </Typography>*/}
                        {/*    <Typography component="div" gutterBottom className={"pre-wrap"}>*/}
                        {/*        { this.props.currentParticipant.instructorComment }*/}
                        {/*    </Typography>*/}
                        {/*</div>*/}
                        {/*}*/}

                        { this.state.transpondeur > 0 && !this.state.updateTranspondeur &&
                        <div className="mt2">
                            <Grid container spacing={3} alignItems={'center'}>
                                <Grid item xs={12} sm={8}>
                                    <Typography component="h5" variant="h4">
                                        Numéro RFID : { this.state.transpondeur }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={this.toggleUpdateTranspondeur.bind(this)}
                                    >
                                        Modifier
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        }

                        {this.state.updateTranspondeur &&
                        <div className="mt2">
                            <TextField
                                id="transpondeur"
                                label="Transpondeur"
                                value={this.state.transpondeur}
                                required
                                onChange={this.handleChange.bind(this, 'transpondeur')}
                                margin="normal"
                                type="number"
                                error={this.state.transpondeur <1 }
                                inputProps={{'min': 1}}
                                fullWidth
                            />
                            <div className={'mt2'}>
                                <Grid container justifyContent="center" spacing={1}>
                                    <Grid item  xs={12} sm={6}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={this.handleSubmit.bind(this)}
                                        >
                                            Enregistrer
                                        </Button>
                                    </Grid>
                                    <Grid item  xs={12} sm={6}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            onClick={this.toggleUpdateTranspondeur.bind(this)}
                                        >
                                            Annuler
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        }

                        { this.state.plateNumber > 0 &&
                            <div className="mt2">
                                <Grid container spacing={3} alignItems={'center'}>
                                    <Grid item xs={12} sm={8}>
                                        <Typography component="h5" variant="h4">
                                            Numéro de plaque : { this.state.plateNumber }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={this.toggleUpdatePlateNumber.bind(this)}
                                        >
                                            Modifier
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }

                        {this.state.updatePlateNumber &&
                            <div className="mt2">
                                <TextField
                                    id="plateNumber"
                                    label="Numéro de plaque"
                                    value={this.state.plateNumber}
                                    required
                                    onChange={this.handleChange.bind(this, 'plateNumber')}
                                    margin="normal"
                                    type="number"
                                    error={this.state.plateNumber <1 }
                                    inputProps={{'min': 1}}
                                    fullWidth
                                />
                                <div className={'mt2'}>
                                    <Grid container justifyContent="center" spacing={1}>
                                        <Grid item  xs={12} sm={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={this.handleSubmit.bind(this)}
                                            >
                                                Enregistrer
                                            </Button>
                                        </Grid>
                                        <Grid item  xs={12} sm={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                onClick={this.toggleUpdatePlateNumber.bind(this)}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        }

                        {this.props.currentParticipant.validePresence &&
                        <div className="mt2">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={this.resetParticipant.bind(this)}
                                className={'mt2'}
                            >
                                Pilote suivant
                            </Button>
                        </div>
                        }

                    </Paper>
                    <Paper className="formPaper mt2">
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item  xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {this.props.history.push('/contact')}}
                                >
                                    <NavigateBefore/>
                                    Infos réservation
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        )
    }
}

export default Notes